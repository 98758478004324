import React from 'react'

export default () => (
  <div>
    <label htmlFor='gender-selector'>names given to: </label>
    <select id='gender-selector'>
      <option>both men and women</option>
      <option>men</option>
      <option>women</option>
    </select>
  </div>
)
